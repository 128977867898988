import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../services/auth.services';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const VerifyToken = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const searchParams = new URLSearchParams(window.location.search);
        const token = searchParams.get('token');

        if (!token) {
          throw new Error('Invalid token');
        }

        const response = await authService.verifyToken(token);
        if (!response.error) {
          toast.success(response.message, {
            style: { backgroundColor: '#212332', color: 'white' },
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
          // redirect to new password page
          navigate(`/new-password?token=${token}`);
        } else {
          // redirect to login
          displayError(response.error);
          navigate('/login');
        }
      } catch (err) {
        displayError(err.message || err.error);
        setTimeout(() => {
          navigate('/login');
        }, 1500);
      }
    };

    verifyToken();
  }, [navigate]);

  const displayError = (error) => {
    if (Array.isArray(error)) {
      error.forEach((err) => {
        toast.error(err, {
          style: { backgroundColor: '#212332', color: 'white' },
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      });
    } else {
      toast.error(error, {
        style: { backgroundColor: '#212332', color: 'white' },
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return null;
};

export default VerifyToken;
