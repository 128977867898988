import React, { useState, useEffect, useMemo, useContext, useRef } from 'react';
import meetingsService from '../services/meeting.services';
import Container from '../components/container/Container';
import Layout from '../components/layout/Layout';
import { format } from 'date-fns';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './Meetings.css';
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthProvider';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import { setTokenCookie } from '../utils/cookies';
import {
  getDayName,
  getMeetingNextOccurrences,
} from '../components/meetups/utils';

const JoinMeetingButton = ({ meetingId }) => {
  const onClick = async () => {
    const {
      data: { meeting, accessToken },
    } = await meetingsService.joinMeeting(meetingId);
    setTokenCookie(accessToken);
    window.open(
      `${process.env.REACT_APP_SUHAIL_MEET_URL}/${meeting.meetingId}`,
      '_blank'
    );
  };
  return (
    <button
      onClick={onClick}
      className="bg-blue-600 hover:bg-blue-800"
      style={{
        color: 'white',
        padding: '8px 14px',
        textAlign: 'center',
        textDecoration: 'none',
        fontSize: '16px',
        borderRadius: '5px',
        transition: 'background-color 0.3s',
      }}
    >
      Join Meeting
    </button>
  );
};

const deletePopupStyles = {
  width: '350px',
  padding: '20px',
  maxWidth: '90vw',
  maxHeight: '90vh',
  borderRadius: '10px',
  color: '#000',
};
const Meetings = () => {
  const { auth } = useContext(AuthContext);
  const [meetings, setMeetings] = useState([]);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentUser, setCurrentUser] = useState({});
  const [activeTab, setActiveTab] = useState('creator');
  const [deleteOption, setDeleteOption] = useState(null);

  const navigate = useNavigate();

  async function fetchMeetings() {
    try {
      const data = await meetingsService.getAllMeetings();
      const allMeetings = [];

      for (const key in data.meetings) {
        const meetingInfo = {
          rowId: key,
          ...data.meetings[key],
        };

        if (meetingInfo.isRecurring) {
          // upcomingOccurrences = ["2024-11-15T08:11:00.000Z", "2024-11-20T08:11:00.000Z", etc]
          const upcomingOccurrences = getMeetingNextOccurrences(meetingInfo);

          upcomingOccurrences.forEach((recurrenceDate, index) => {
            allMeetings.push({
              ...meetingInfo,
              startTime: recurrenceDate,
              rowId: `${key}_${index}`,
              dayName: getDayName(recurrenceDate),
            });
          });
        } else {
          meetingInfo.dayName = getDayName(meetingInfo.startTime);
          allMeetings.push(meetingInfo);
        }
      }

      // Sort meetings
      allMeetings.sort((a, b) => {
        return new Date(a.startTime) - new Date(b.startTime);
      });

      setMeetings(allMeetings);
    } catch (err) {
      setError(err.message);
    }
  }

  useEffect(() => {
    if (auth.user) {
      setCurrentUser(auth.user);
    }

    fetchMeetings();
  }, [auth]);

  const getUpcomingMeetings = (meetingsList) => {
    const now = new Date();
    return meetingsList.filter((meeting) => new Date(meeting.startTime) > now);
  };

  const getPastMeetings = (meetingsList) => {
    const now = new Date();
    return meetingsList.filter((meeting) => new Date(meeting.startTime) <= now);
  };

  const createdMeetings = useMemo(
    () => meetings.filter((meeting) => meeting.createdBy === currentUser.id),
    [meetings, currentUser.id]
  );
  const moderatingMeetings = useMemo(
    () =>
      meetings.filter(
        (meeting) =>
          meeting.moderators && meeting.moderators.includes(currentUser.email)
      ),
    [meetings, currentUser.email]
  );
  const invitedMeetings = useMemo(
    () =>
      meetings.filter(
        (meeting) =>
          meeting.invitees && meeting.invitees.includes(currentUser.email)
      ),
    [meetings, currentUser.email]
  );

  const filteredMeetings = (meetingsList) => {
    return meetingsList.filter(
      (meeting) =>
        meeting.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        meeting.meetingId.includes(searchTerm)
    );
  };

  async function handleDelete(deletingMeeting) {
    try {
      let updatedMeetings = [];

      // deleteOption state = either meeting or single occurence for recurring meeting
      if (deleteOption === 'single') {
        // Exclude the current day from recurrings
        const updatedRecurringDays = meetings
          .filter(
            (meet) =>
              meet.id === deletingMeeting.id &&
              meet.dayName !== deletingMeeting.dayName
          )
          .map((meet) => meet.dayName);

        await meetingsService.updateMeeting(deletingMeeting.id, {
          recurringDays: updatedRecurringDays,
        });

        // update meetings state
        updatedMeetings = meetings
          .filter((meet) => meet.rowId !== deletingMeeting.rowId)
          .map((meet) =>
            meet.id === deletingMeeting.id
              ? {
                  ...meet,
                  recurrings: {
                    weekly: meet.recurrings.weekly.filter(
                      (occurence) => occurence.label !== deletingMeeting.dayName
                    ),
                  },
                }
              : meet
          );
      } else {
        await meetingsService.deleteMeeting(deletingMeeting.id);

        // update meetings state
        updatedMeetings = meetings.filter(
          (meet) => meet.id !== deletingMeeting.id
        );
      }

      setMeetings(updatedMeetings);

      toast.success('Meeting has been deleted successfully', {
        style: { backgroundColor: '#212332', color: 'white' },
        draggable: true,
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error(error);
      toast.success('An error occured. Try again later', {
        style: { backgroundColor: '#212332', color: 'white' },
        draggable: true,
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const renderMeetingCard = (meeting, isPast) => (
    <div
      key={meeting.rowId}
      className={`p-4 border rounded shadow-md text-white bg-gray-800 ${
        isPast ? 'grayscale opacity-70' : ''
      }`}
    >
      <h3 className="text-xl font-bold">{meeting.title}</h3>
      <p className="text-gray-400">Meeting Id: {meeting.meetingId}</p>
      <p className="text-gray-400">
        Start Time: {format(new Date(meeting.startTime), 'dd MMM yyyy / p')}
      </p>
      <div className="mt-2 flex flex-wrap gap-4">
        {(meeting.createdBy === currentUser.id ||
          (meeting.moderators &&
            meeting.moderators.includes(currentUser.email))) && (
          <>
            <button
              onClick={() => {
                navigate(`/meeting/${meeting.meetingId}/edit`);
              }}
              className="text-blue-500 hover:underline"
            >
              Edit
            </button>
            <Popup
              trigger={
                <button className="text-red-500 hover:underline">Delete</button>
              }
              onOpen={() => {
                setDeleteOption(null);
              }}
              modal
              contentStyle={deletePopupStyles}
            >
              {(close) => (
                <div className="modal">
                  <div
                    className="header"
                    style={{ fontSize: '1.5em', marginBottom: '10px' }}
                  >
                    Confirm delete
                  </div>
                  <div className="content mb-6">
                    <p>
                      {meeting.isRecurring
                        ? 'You are trying to remove a recurring meeting.'
                        : 'Are you sure you want to delete this meeting?'}
                    </p>
                    {meeting.isRecurring &&
                      meeting.recurrings?.weekly?.length > 1 && (
                        <div className="mt-4">
                          <label>
                            <input
                              style={{ marginRight: '10px' }}
                              type="radio"
                              name="deleteOption"
                              value="single"
                              onChange={(e) => setDeleteOption(e.target.value)}
                            />
                            Delete only this occurrence
                          </label>
                          <label>
                            <input
                              style={{ marginRight: '10px' }}
                              type="radio"
                              name="deleteOption"
                              value="all"
                              onChange={(e) => setDeleteOption(e.target.value)}
                            />
                            Delete the entire meeting
                          </label>
                        </div>
                      )}
                  </div>
                  <div className="actions">
                    <button
                      className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                      onClick={() => {
                        if (
                          !deleteOption &&
                          meeting.isRecurring &&
                          meeting.recurrings?.weekly?.length > 1
                        ) {
                          toast.error('Please select an option to delete', {
                            style: {
                              backgroundColor: '#212332',
                              color: 'white',
                            },
                            draggable: true,
                            position: toast.POSITION.TOP_RIGHT,
                          });
                          return;
                        }
                        handleDelete(meeting);
                        close();
                      }}
                    >
                      Delete
                    </button>
                    <button
                      className="bg-gray-300 text-black px-4 py-2 rounded"
                      onClick={close}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </Popup>
          </>
        )}

        <div className="ml-auto">
          <JoinMeetingButton meetingId={meeting.meetingId} />
        </div>
      </div>
    </div>
  );

  const getMeetingsForTab = () => {
    switch (activeTab) {
      case 'creator':
        return filteredMeetings(createdMeetings);
      case 'moderator':
        return filteredMeetings(moderatingMeetings);
      case 'invitee':
        return filteredMeetings(invitedMeetings);
      default:
        return [];
    }
  };

  const upcomingMeetings = getUpcomingMeetings(getMeetingsForTab());
  const pastMeetings = getPastMeetings(getMeetingsForTab());

  return (
    <Layout>
      <Container>
        <ToastContainer
          position="top-center"
          draggable={false}
          autoClose={2500}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          transition={Zoom}
          closeButton={false}
        />
        <div className="w-full px-5 pb-5">
          <div className="flex justify-between flex-wrap items-center mb-4">
            <h2 className="text-4xl font-bold">Meetings</h2>
            <div className="search-container">
              <input
                type="text"
                placeholder="Search by title or ID"
                className="p-2 border rounded outline-none text-black search-input"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Link
                to="/meeting/new"
                className="ml-4 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded"
              >
                New Meeting
              </Link>
            </div>
          </div>

          {error && <p className="text-red-500">{error}</p>}

          <div className="mb-4 flex gap-4 meeting-tabs">
            <button
              className={`px-4 py-2 rounded ${
                activeTab === 'creator'
                  ? 'bg-blue-600 hover:bg-blue-700 text-white'
                  : 'bg-gray-200 text-black'
              }`}
              onClick={() => setActiveTab('creator')}
            >
              Created Meetings
            </button>
            <button
              className={`px-4 py-2 rounded ${
                activeTab === 'invitee'
                  ? 'bg-blue-600 hover:bg-blue-700 text-white'
                  : 'bg-gray-200 text-black'
              }`}
              onClick={() => setActiveTab('invitee')}
            >
              Invited Meetings
            </button>
            <button
              className={`px-4 py-2 rounded ${
                activeTab === 'moderator'
                  ? 'bg-blue-600 hover:bg-blue-700 text-white'
                  : 'bg-gray-200 text-black'
              }`}
              onClick={() => setActiveTab('moderator')}
            >
              Moderating Meetings
            </button>
          </div>

          <div className="mt-6">
            <h3 className="text-2xl font-bold mb-4">Upcoming Meetings</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {upcomingMeetings.length > 0 ? (
                upcomingMeetings.map((meeting) =>
                  renderMeetingCard(meeting, false)
                )
              ) : (
                <p>No upcoming meetings</p>
              )}
            </div>
          </div>

          <div className="mt-6">
            <h3 className="text-2xl font-bold mb-4">Past Meetings</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {pastMeetings.length > 0 ? (
                pastMeetings.map((meeting) => renderMeetingCard(meeting, true))
              ) : (
                <p>No past meetings</p>
              )}
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Meetings;
