import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './vela_ft.svg';
import AuthService from '../services/auth.services';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SigninForm from './SigninForm';

const TOAST_OPTIONS = {
  style: { backgroundColor: '#212332', color: 'white' },
  draggable: true,
  position: 'top-center',
};

function ForgotPassword() {
  const [step, setStep] = useState(1); // 1: Enter email, 2: Enter OTP, 3: Set new password
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [otpResendTime, setOtpResendTime] = useState(0);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (isEmailValid(email)) {
      try {
        if (typeof otpResendTime !== 'undefined' && otpResendTime <= 0) {
          const response = await AuthService.resetPassword(email);
          if (!response.error) {
            toast.success(response.message, TOAST_OPTIONS);
            setEmailSent(true);
            setStep(2);
          } else {
            displayError(response.error);
          }
        } else {
          displayError(
            `Please wait ${otpResendTime} seconds before requesting another OTP`
          );
        }
      } catch (err) {
        let nextResendTime = err.message?.match(/\d+/);
        if (nextResendTime) {
          nextResendTime = parseInt(nextResendTime[0]);
          setOtpResendTime(nextResendTime);
        }

        displayError(err.message || err.error);
      }
    } else {
      displayError('Invalid email address');
    }
  };

  useEffect(() => {
    let timer;
    if (otpResendTime > 0) {
      timer = setTimeout(() => setOtpResendTime(otpResendTime - 1), 1000);
    }

    return () => clearTimeout(timer);
  }, [otpResendTime]);

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    if (otp && newPassword && confirmPassword) {
      if (newPassword !== confirmPassword) {
        displayError('Passwords do not match');
        return;
      }
      try {
        const response = await AuthService.changePassword({
          email,
          newPassword,
          otp,
        });
        if (response.status === 200 || response.status === 201) {
          toast.success(
            response.message ?? 'Password has been reset successfully',
            TOAST_OPTIONS
          );
          setTimeout(() => {
            navigate('/login');
          }, 2000);
        } else {
          displayError('Invalid OTP or email');
        }
      } catch (error) {
        // Check if the error response has validation messages
        const errMessage =
          error.response?.data?.message || 'Error resetting password';
        if (Array.isArray(errMessage)) {
          if (errMessage.length > 0) {
            displayError(errMessage[0]);
          } else {
            displayError(errMessage);
          }
        } else {
          displayError(errMessage);
        }
      }
    } else {
      displayError('Please enter OTP, new password, and confirm password');
    }
  };

  const isEmailValid = (email = '') => {
    if (!email) {
      return false;
    }

    // Basic regex for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const displayError = (error) => {
    if (Array.isArray(error)) {
      error.forEach((err) => {
        toast.error(err, TOAST_OPTIONS);
      });
    } else {
      toast.error(error, TOAST_OPTIONS);
    }
  };

  return (
    <>
      <SigninForm>
        <ToastContainer
          position="top-center"
          draggable={false}
          autoClose={2500}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          transition={Zoom}
          closeButton={false}
        />
        <div>
          <div
            style={{
              position: 'fixed',
              zIndex: '9999',
              inset: '16px',
              pointerEvents: 'none',
            }}
          ></div>
          <div>
            <div
              className="flex rounded-lg justify-center max-w-2xl mx-auto my-auto w-11/12 lg:w-10/12 overflow-auto"
              style={{ backgroundColor: 'rgb(25, 27, 40)' }}
            >
              <div className="m-auto w-full md:w-5/12 lg:w-1/2">
                {step === 1 && (
                  <form
                    action="#"
                    className="flex flex-col content-center text-center p-1/10"
                  >
                    <img className="mt-7 mx-auto" src={logo} alt="logo" />
                    <div className="my-5">
                      <h2 className="text-2xl">Reset Your Password</h2>
                    </div>
                    <div className="form-group">
                      <input
                        name="email"
                        type="email"
                        placeholder="Type your email here..."
                        className="form-control w-11/12 my-2 px-3 py-3 rounded-sm"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div>
                      <button
                        onClick={handleEmailSubmit}
                        className="text-white rounded-sm py-3 my-3 w-11/12"
                        type="submit"
                        style={{
                          backgroundColor:
                            otpResendTime <= 0
                              ? 'rgb(47, 193, 157)'
                              : 'rgb(71 85 105)',
                        }}
                        disabled={otpResendTime > 0}
                      >
                        Send OTP{' '}
                        {otpResendTime > 0 ? `(${otpResendTime}s)` : ''}
                      </button>
                      <button
                        onClick={() => {
                          navigate('/login');
                        }}
                        className="text-white rounded-sm py-3 mb-6 mt-2 w-11/12"
                        type="button"
                        style={{ backgroundColor: '#dc2626' }}
                      >
                        Back
                      </button>
                    </div>
                  </form>
                )}

                {step === 2 && emailSent && (
                  <form
                    action="#"
                    className="flex flex-col content-center text-center p-1/10"
                  >
                    <img className="mt-7 mx-auto" src={logo} alt="logo" />
                    <div className="my-5">
                      <h2 className="text-2xl">Verify OTP</h2>
                    </div>
                    <div className="form-group">
                      <input
                        name="otp"
                        type="text"
                        placeholder="Enter OTP here..."
                        className="form-control w-11/12 my-2 px-3 py-3 rounded-sm"
                        onChange={(e) => setOtp(e.target.value)}
                      />
                      <input
                        name="newPassword"
                        type="password"
                        placeholder="Enter new password..."
                        className="form-control w-11/12 my-2 px-3 py-3 rounded-sm"
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                      <input
                        name="confirmPassword"
                        type="password"
                        placeholder="Confirm new password..."
                        className="form-control w-11/12 my-2 px-3 py-3 rounded-sm"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                    <div>
                      <button
                        onClick={handleOtpSubmit}
                        className="text-white rounded-sm py-3 my-3 w-11/12"
                        type="submit"
                        style={{
                          backgroundColor:
                            otp.length >= 6 && newPassword && confirmPassword
                              ? 'rgb(47, 193, 157)'
                              : 'rgb(71 85 105)',
                        }}
                        disabled={
                          !(otp.length >= 6 && newPassword && confirmPassword)
                        }
                      >
                        Reset Password
                      </button>
                      <button
                        onClick={() => {
                          setStep(1);
                          setEmail('');
                          setOtp('');
                          setNewPassword('');
                          setConfirmPassword('');
                          setEmailSent(false);
                        }}
                        className="text-white rounded-sm py-3 mb-6 mt-2 w-11/12"
                        type="button"
                        style={{ backgroundColor: '#dc2626' }}
                      >
                        Back
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </SigninForm>
    </>
  );
}

export default ForgotPassword;
