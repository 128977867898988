import React from 'react';
import meetingsService from '../../services/meeting.services';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Host() {
  async function handleClick() {
    try {
      const meetupData = {
        title: 'New meeting',
        description: '',
        startTime: new Date(),
        password: '',
        hasWaitingRoom: true,
        muteParticipantsUponEntry: true,
        allowGuests: true,
        allowSystemUsers: true,
        isRecurring: false,
        recurringDays: null,
        invitees: null,
        moderators: null,
      };

      const { meeting } = await meetingsService.postMeetings(meetupData);

      if (!process.env.REACT_APP_SUHAIL_MEET_URL) {
        console.error('Env var REACT_APP_SUHAIL_MEET_URL not found.');
        return;
      }

      window.open(
        `${process.env.REACT_APP_SUHAIL_MEET_URL}/${meeting.meetingId}`,
        '_blank'
      );
    } catch (err) {
      displayError(err.message || err.error);
      console.error('Failed to host a meeting:', err);
    }
  }

  const displayError = (error) => {
    if (Array.isArray(error)) {
      error.forEach((err) => {
        toast.error(err, {
          style: { backgroundColor: '#212332', color: 'white' },
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      });
    } else {
      toast.error(error, {
        style: { backgroundColor: '#212332', color: 'white' },
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <div
      className="my-3 rounded-md text-center items-center p-6"
      style={{ backgroundColor: 'rgba(45, 49, 66, 0.75)' }}
    >
      <h2 className="font-bold text-xl">Host A Meeting</h2>
      <p className="m-3">Start Meeting Now</p>
      <button
        onClick={() => handleClick()}
        className="flex-shrink-0 bg-teal-500 text-sm text-white py-2 px-2 w-11/12 font-bold"
        type="button"
        style={{ backgroundColor: 'rgb(242, 109, 33)' }}
      >
        Host
      </button>
    </div>
  );
}

export default Host;
