import React, { useState } from 'react';
import meetingsService from '../../services/meeting.services';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setTokenCookie } from '../../utils/cookies';

const MEETING_CODE_LENGTH = 4; // Change if needed

const TOAST_OPTIONS = {
  style: { backgroundColor: '#212332', color: 'white' },
  draggable: true,
  position: 'top-center',
};

function Join() {
  const [code, setCode] = useState('');
  const [isError, setIsError] = useState(true);

  const handleJoin = async () => {
    if (!code || code.length !== MEETING_CODE_LENGTH) {
      displayError(`Please enter a valid meeting code`);
      return;
    }

    if (!process.env.REACT_APP_SUHAIL_MEET_URL) {
      console.error('Env var REACT_APP_SUHAIL_MEET_URL not found.');
      return;
    }

    try {
      const result = await meetingsService.joinMeeting(code);
      if (result.status === 200) {
        setIsError(false);
        const { accessToken, meeting } = result.data;
        setTokenCookie(accessToken);
        window.open(
          `${process.env.REACT_APP_SUHAIL_MEET_URL}/${meeting.meetingId}`,
          '_blank'
        );
      }
    } catch (err) {
      console.error('Failed to join a meeting:', err);
      const errorMessage =
        err?.response?.data?.message || 'An unexpected error occurred';
      setIsError(true);
      displayError(errorMessage);
    }
  };

  function displayError(error) {
    if (Array.isArray(error)) {
      error.forEach((err) => toast.error(err, TOAST_OPTIONS));
    } else {
      toast.error(error, TOAST_OPTIONS);
    }
  }

  return (
    <>
      <ToastContainer
        position="top-center"
        draggable={false}
        autoClose={2500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        transition={Zoom}
        closeButton={false}
      />
      <div
        className="my-3 rounded-md text-center p-6"
        style={{ backgroundColor: 'rgba(45, 49, 66, 0.75)' }}
      >
        <h2 className="font-bold text-xl">Join A Meeting</h2>
        <p className="m-3">Instantly join a meeting</p>
        <div className="flex bg-white">
          <input
            className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-2 px-2 leading-tight focus:outline-none"
            type="text"
            placeholder="Enter code"
            onChange={(e) => setCode(e.target.value)}
          />
          <button
            className="flex-shrink-0 bg-teal-500 text-sm text-white py-2 px-2 font-bold"
            type="button"
            style={{ backgroundColor: 'rgb(41, 196, 155)' }}
            onClick={handleJoin}
          >
            JOIN
          </button>
        </div>
      </div>
    </>
  );
}

export default Join;
