import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import meetingsService from '../../services/meeting.services';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import { format } from 'date-fns';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import 'react-toastify/dist/ReactToastify.css';
import { setTokenCookie, removeTokenCookie } from '../../utils/cookies';
import { CalendarFile } from './CalendarFile';
import {
  generateGoogleCalendarLink,
  generateMicrosoftCalendarLink,
  getDayName,
  getMeetingNextOccurrences,
} from './utils';

const deletePopupStyles = {
  width: '350px',
  padding: '20px',
  maxWidth: '90vw',
  maxHeight: '90vh',
  borderRadius: '10px',
  color: '#000',
};

const JoinMeetingButton = ({ meetingId }) => {
  const onClick = async () => {
    const {
      data: { meeting, accessToken },
    } = await meetingsService.joinMeeting(meetingId);
    setTokenCookie(accessToken);
    window.open(
      `${process.env.REACT_APP_SUHAIL_MEET_URL}/${meeting.meetingId}`,
      '_blank'
    );
  };
  return (
    <button
      onClick={onClick}
      style={{
        backgroundColor: '#4CAF50', // Green background
        border: 'none', // No borders
        color: 'white', // White text
        padding: '10px 20px', // Padding
        textAlign: 'center', // Centered text
        textDecoration: 'none', // No underline
        display: 'inline-block', // Inline-block display
        fontSize: '16px', // Font size
        margin: '4px 2px', // Margin
        cursor: 'pointer', // Pointer cursor on hover
        borderRadius: '5px', // Rounded corners
        transition: 'background-color 0.3s', // Smooth background color transition
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = '#45a049'; // Darker green on hover
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = '#4CAF50'; // Original green
      }}
    >
      Join Meeting
    </button>
  );
};

function Upcoming({ updateFirstMeetup }) {
  const [isLoading, setIsLoading] = useState(true);
  const [loadedMeetups, setLoadedMeetups] = useState([]);
  const [currentUserEmail, setCurrentUserEmail] = useState('');
  const [currentUserId, setCurrentUserId] = useState('');
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [deleteOption, setDeleteOption] = useState(null);

  const toggleCalendarMenu = (id) => {
    setOpenDropdownId(openDropdownId === id ? null : id);
  };
  const handleDropdownItemClick = (e, link) => {
    setOpenDropdownId(null);
    window.open(link, '_blank');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const activeDropdown = document.querySelector(
        `.dropdown-menu-${openDropdownId}`
      );
      if (activeDropdown && !activeDropdown.contains(event.target)) {
        setOpenDropdownId(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openDropdownId]);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchUserInfo() {
      try {
        const res = await meetingsService.getUserInfo();
        setCurrentUserId(res.data.id);
        setCurrentUserEmail(res.data.email);
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    }

    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (currentUserEmail && currentUserId) {
      updateMeetings();
    }
  }, [currentUserEmail, currentUserId]);

  function updateMeetings() {
    setIsLoading(true);
    meetingsService
      .getAllUpcomingMeetings()
      .then((response) => {
        const meetups = [];
        const data = response.data.meetings;

        if (response.data.error) {
          console.error(response.data.error);
          localStorage.removeItem('accessToken');
          removeTokenCookie();
          navigate('/login');
        }

        for (const key in data) {
          const meetup = {
            rowId: key,
            updateFlag: false,
            deleteFlag: false,
            ...data[key],
          };

          if (
            currentUserId == meetup.createdBy ||
            (Array.isArray(meetup.moderators) &&
              meetup.moderators.includes(currentUserEmail))
          ) {
            meetup.deleteFlag = true;
            meetup.updateFlag = true;
          }

          if (meetup.isRecurring) {
            // upcomingOccurrences = ["2024-11-15T08:11:00.000Z", "2024-11-20T08:11:00.000Z", etc]
            const upcomingOccurrences = getMeetingNextOccurrences(meetup);

            upcomingOccurrences.forEach((recurrenceDate, index) => {
              const recMeeting = {
                ...meetup,
                startTime: recurrenceDate,
                rowId: `${key}_${index}`,
                dayName: getDayName(recurrenceDate),
              };

              recMeeting.calendarLinks = generateCalendarLinks(recMeeting);
              meetups.push(recMeeting);
            });
          } else {
            meetup.calendarLinks = generateCalendarLinks(meetup);
            meetup.dayName = getDayName(meetup.startTime);
            meetups.push(meetup);
          }
        }

        // Sort meetings
        meetups.sort((a, b) => {
          return new Date(a.startTime) - new Date(b.startTime);
        });

        setIsLoading(false);
        setLoadedMeetups(meetups);

        // Updating parent component which has 'Upcoming Meeting' section
        setFirstUpcomingMeeting(meetups);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function setFirstUpcomingMeeting(meetups) {
    if (meetups.length <= 0) {
      updateFirstMeetup({
        isUpcoming: false,
      });
    } else {
      const closestMeetup = meetups[0];
      updateFirstMeetup({
        id: closestMeetup.meetingId,
        isUpcoming: true,
        upcomingTitle: format(
          new Date(closestMeetup.startTime),
          'dd MMM yyyy / p'
        ),
      });
    }
  }

  function generateCalendarLinks(meeting) {
    return [
      {
        id: `${meeting.rowId}_google_1`,
        label: 'Google',
        link: generateGoogleCalendarLink(meeting),
      },
      {
        id: `${meeting.rowId}_outook_2`,
        label: 'Outlook',
        link: generateMicrosoftCalendarLink(meeting, 'normal'),
      },
      {
        id: `${meeting.rowId}_office_3`,
        label: 'Office365',
        link: generateMicrosoftCalendarLink(meeting, 'business'),
      },
    ];
  }

  async function handleDelete(deletingMeeting) {
    try {
      let updatedMeetings = [];

      // deleteOption state = either meeting or single occurence for recurring meeting
      if (deleteOption === 'single') {
        // Exclude the current day from recurrings
        const updatedRecurringDays = loadedMeetups
          .filter(
            (meet) =>
              meet.id === deletingMeeting.id &&
              meet.dayName !== deletingMeeting.dayName
          )
          .map((meet) => meet.dayName);

        await meetingsService.updateMeeting(deletingMeeting.id, {
          recurringDays: updatedRecurringDays,
        });

        // update meetings state
        updatedMeetings = loadedMeetups
          .filter((meet) => meet.rowId !== deletingMeeting.rowId)
          .map((meet) =>
            meet.id === deletingMeeting.id
              ? {
                  ...meet,
                  recurrings: {
                    weekly: meet.recurrings.weekly.filter(
                      (occurence) => occurence.label !== deletingMeeting.dayName
                    ),
                  },
                }
              : meet
          );
      } else {
        await meetingsService.deleteMeeting(deletingMeeting.id);

        // update meetings state
        updatedMeetings = loadedMeetups.filter(
          (meet) => meet.id !== deletingMeeting.id
        );
      }

      // Update upcoming meetings
      setLoadedMeetups(updatedMeetings);

      // Updating parent component which has 'Upcoming Meeting' section
      setFirstUpcomingMeeting(updatedMeetings);

      toast.success('Meeting has been deleted successfully', {
        style: { backgroundColor: '#212332', color: 'white' },
        draggable: true,
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error(error);
      toast.success('An error occured. Try again later', {
        style: { backgroundColor: '#212332', color: 'white' },
        draggable: true,
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  function copyToClipboard() {
    toast.success('Copied to clipboard!', {
      style: { backgroundColor: '#212332', color: 'white' },
      draggable: true,
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  if (isLoading) {
    return (
      <section>
        <p> Loading ...</p>
      </section>
    );
  }

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table
        style={{
          width: '100vw',
          maxWidth: '100%',
          borderCollapse: 'collapse',
          marginBottom: '120px',
          borderRadius: '10px',
          overflowX: 'hidden',
          textAlign: 'left',
        }}
      >
        <thead
          style={{ backgroundColor: 'rgba(255,255,255,0.18', color: '#fff' }}
        >
          <tr>
            <th className="p-3">Meeting ID</th>
            <th className="p-3">Title</th>
            <th className="p-3">Start Time</th>
            <th className="p-3">Invitees</th>
            <th className="p-3">Calendar</th>
            <th className="p-3">Actions</th>
            <th className="p-3">Join Meeting</th>
          </tr>
        </thead>
        <tbody>
          {loadedMeetups.map((meeting) => (
            <tr key={meeting.rowId} className="border-b ">
              <td className="p-3">
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '7px' }}
                >
                  {meeting.meetingId}
                </div>
              </td>

              <td className="p-3" style={{ minWidth: '150px' }}>
                {meeting.title}
              </td>
              <td className="p-3" style={{ minWidth: '125px' }}>
                {format(new Date(meeting.startTime), 'dd MMM yyyy / p')}
              </td>
              <td
                className="p-3"
                title={meeting.invitees ? meeting.invitees.join(', ') : ''}
                style={{
                  maxWidth: '150px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {meeting.invitees ? meeting.invitees.join(', ') : ''}
              </td>
              <td className="p-3">
                <div className="relative inline-block text-left">
                  <div>
                    <button
                      type="button"
                      className={`inline-flex justify-center rounded-md border text-slate-300 hover:bg-slate-900 transition duration-200 px-4 py-2 text-sm font-medium
                        ${
                          openDropdownId === meeting.rowId
                            ? 'bg-slate-900'
                            : 'bg-slate-800'
                        }`}
                      onClick={() => toggleCalendarMenu(meeting.rowId)}
                    >
                      Add to Calendar
                    </button>
                  </div>

                  <div
                    className={`absolute right-0 z-50 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition-opacity duration-300 ease-in-out
                      ${
                        openDropdownId === meeting.rowId
                          ? 'block opacity-100 translate-y-0'
                          : 'hidden opacity-0 translate-y-2'
                      } dropdown-menu-${meeting.rowId}`}
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="calendar-menu-button"
                  >
                    <ul className="p-1" role="none">
                      {meeting.calendarLinks.map((option) => (
                        <li
                          key={option.id}
                          onClick={(e) =>
                            handleDropdownItemClick(e, option.link)
                          }
                          className="cursor-pointer border-b border-gray-300 block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200"
                          role="menuitem"
                        >
                          {option.label}
                        </li>
                      ))}
                      <li className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200">
                        <CalendarFile
                          meeting={meeting}
                          updateOpenDropdown={setOpenDropdownId}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
              <td className="p-3">
                <div className="inline-flex gap-1 space-x-2">
                  {/* edit meeting */}
                  {meeting.updateFlag ? (
                    <button
                      title="Edit meeting"
                      onClick={() => {
                        navigate(`/meeting/${meeting.meetingId}/edit`);
                      }}
                      className="hover:text-blue-300 transition duration-200"
                    >
                      <svg
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        width="1.5em"
                        height="1.5em"
                        viewBox="0 0 30 30"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        className="mr-1"
                      >
                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                      </svg>
                    </button>
                  ) : (
                    ''
                  )}
                  {/* delete meeting */}
                  {meeting.deleteFlag ? (
                    <Popup
                      trigger={
                        <button
                          title="Delete meeting"
                          className="hover:text-red-400 transition duration-200"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1.5em"
                            height="1.5em"
                            viewBox="0 0 30 30"
                            fill="currentColor"
                            className="mr-1"
                          >
                            <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                          </svg>
                        </button>
                      }
                      onOpen={() => {
                        setDeleteOption(null);
                      }}
                      modal
                      contentStyle={deletePopupStyles}
                    >
                      {(close) => (
                        <div className="modal">
                          <div
                            className="header"
                            style={{ fontSize: '1.5em', marginBottom: '10px' }}
                          >
                            Confirm delete
                          </div>
                          <div className="content mb-6">
                            <p>
                              {meeting.isRecurring
                                ? 'You are trying to remove a recurring meeting.'
                                : 'Are you sure you want to delete this meeting?'}
                            </p>
                            {meeting.isRecurring &&
                              meeting.recurrings?.weekly?.length > 1 && (
                                <div className="mt-4">
                                  <label>
                                    <input
                                      style={{ marginRight: '10px' }}
                                      type="radio"
                                      name="deleteOption"
                                      value="single"
                                      onChange={(e) =>
                                        setDeleteOption(e.target.value)
                                      }
                                    />
                                    Delete only this occurrence
                                  </label>
                                  <label>
                                    <input
                                      style={{ marginRight: '10px' }}
                                      type="radio"
                                      name="deleteOption"
                                      value="all"
                                      onChange={(e) =>
                                        setDeleteOption(e.target.value)
                                      }
                                    />
                                    Delete the entire meeting
                                  </label>
                                </div>
                              )}
                          </div>
                          <div className="actions">
                            <button
                              className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                              onClick={() => {
                                if (
                                  !deleteOption &&
                                  meeting.isRecurring &&
                                  meeting.recurrings?.weekly?.length > 1
                                ) {
                                  toast.error(
                                    'Please select an option to delete',
                                    {
                                      style: {
                                        backgroundColor: '#212332',
                                        color: 'white',
                                      },
                                      draggable: true,
                                      position: toast.POSITION.TOP_RIGHT,
                                    }
                                  );
                                  return;
                                }
                                handleDelete(meeting);
                                close();
                              }}
                            >
                              Delete
                            </button>
                            <button
                              className="bg-gray-300 text-black px-4 py-2 rounded"
                              onClick={close}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </Popup>
                  ) : (
                    ''
                  )}

                  {/* copy meeting link */}
                  <CopyToClipboard
                    text={`${process.env.REACT_APP_SUHAIL_MEET_URL}/${meeting.meetingId}`}
                  >
                    <button
                      className="hover:text-blue-300 transition duration-200"
                      onClick={copyToClipboard}
                      title="Copy Invitation Link"
                    >
                      <svg
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="m-auto"
                        height="1.5em"
                        width="1.5em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="9"
                          y="9"
                          width="13"
                          height="13"
                          rx="2"
                          ry="2"
                        ></rect>
                        <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                      </svg>
                    </button>
                  </CopyToClipboard>
                </div>
              </td>
              <td className="p-3">
                <JoinMeetingButton meetingId={meeting.meetingId} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Upcoming;
