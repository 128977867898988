import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Signin.css';
import SigninForm from './SigninForm';
import logo from './vela_ft.svg';
import AuthService from '../services/auth.services';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function NewPassword() {
  const [token, setToken] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  // Retrieve token from query params
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('token');
    if (!token) {
      displayError('Invalid token');
      setTimeout(() => {
        navigate('/login');
      }, 1500);
    }

    setToken(token);
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      return;
    }

    try {
      const payload = { token, newPassword };
      const response = await AuthService.changePassword(payload);

      setErrors({ isLoading: true });
      toast.success(response.data.message, {
        style: { backgroundColor: '#212332', color: 'white' },
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });

      setTimeout(() => {
        navigate('/login');
      }, 1500);
    } catch (err) {
      console.error(err);
      displayError(err.response?.data?.message);
    }
  };

  const validate = () => {
    let vErrors = {};

    if (!newPassword) {
      vErrors.newPassword = 'Password is required';
    } else if (newPassword.length < 6) {
      vErrors.newPassword = 'Password must be at least 6 characters long';
    }

    if (!confirmPassword) {
      vErrors.confirmPassword = 'Confirm password is required';
    } else if (confirmPassword !== newPassword) {
      vErrors.confirmPassword = 'Passwords do not match';
    }

    setErrors(vErrors);
    return Object.keys(vErrors).length === 0;
  };

  const displayError = (error) => {
    if (Array.isArray(error)) {
      error.forEach((err) => {
        toast.error(err, {
          style: { backgroundColor: '#212332', color: 'white' },
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      });
    } else {
      toast.error(error, {
        style: { backgroundColor: '#212332', color: 'white' },
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <SigninForm>
      <ToastContainer
        position="top-center"
        draggable={false}
        autoClose={2500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        transition={Zoom}
        closeButton={false}
      />

      <form
        onSubmit={handleSubmit}
        className="flex flex-col content-center text-center p-1/10"
      >
        <div>
          <img className="mt-7 mx-auto" src={logo} alt="logo" />
          <div className="my-5">
            <h2 className="text-2xl">Set New Password</h2>
          </div>
          <div className="form-group">
            <input
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="form-control w-11/12 my-2 px-3 py-3 rounded-md"
            />
            {errors.newPassword && (
              <label className="form-error-label">{errors.newPassword}</label>
            )}
          </div>
          <div className="form-group">
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="form-control w-11/12 my-2 px-3 py-3 rounded-md"
            />
            {errors.confirmPassword && (
              <label className="form-error-label">
                {errors.confirmPassword}
              </label>
            )}
          </div>
          <div className="form-group">
            <button
              className="text-white rounded-sm py-3 mt-3 mb-6 w-11/12"
              type="submit"
              style={{
                backgroundColor: 'rgb(47, 193, 157)',
              }}
              disabled={errors.isLoading}
            >
              Set Password
            </button>
          </div>
        </div>
      </form>
    </SigninForm>
  );
}

export default NewPassword;
