import axiosInstance from '../api/axios';
import authHeader from './auth-header';
import { setTokenCookie, removeTokenCookie } from '../utils/cookies';

// In here !
const API_AUTH_URL = '/api/user/auth';
const API_USER_URL = '/api/user';

const signup = async (formData) => {
  return axiosInstance
    .post(`${API_AUTH_URL}/signup`, formData)
    .then((response) => {
      if (response.data.accessToken) {
        setTokenCookie(response.data.accessToken);
        localStorage.setItem('accessToken', response.data.accessToken);
      }
      return response.data;
    })
    .catch((error) => {
      throw error.response ? error.response.data : new Error('Signup failed');
    });
};

const verifyOTP = async (email, otp) => {
  return axiosInstance
    .post(`${API_AUTH_URL}/verify`, {
      email,
      otp,
    })
    .then((response) => {
      if (response.data.accessToken) {
        setTokenCookie(response.data.accessToken);
        localStorage.setItem('accessToken', response.data.accessToken);
      }

      return response.data;
    })
    .catch((error) => {
      throw error.response
        ? error.response.data
        : new Error('An error occurred during verification');
    });
};

const resendOTP = async (email) => {
  return axiosInstance
    .post(`${API_AUTH_URL}/sendOTP`, {
      email,
    })
    .then((response) => {
      if (response.data.accessToken) {
        setTokenCookie(response.data.accessToken);
        localStorage.setItem('accessToken', response.data.accessToken);
      }

      return response.data;
    })
    .catch((error) => {
      throw error.response
        ? error.response.data
        : new Error('Failed to resend OTP');
    });
};

const validateEmail = async (email) => {
  return axiosInstance
    .post(`${API_AUTH_URL}/check-user-status`, {
      email,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response ? err.response.data : { error: err.message };
    });
};

const login = async (email, password) => {
  return axiosInstance
    .post(`${API_AUTH_URL}/login`, {
      email,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        setTokenCookie(response.data.accessToken);
        localStorage.setItem('accessToken', response.data.accessToken);
      }
      return response.data;
    })
    .catch((err) => {
      throw err.response ? err.response.data : { error: err.message };
    });
};

const resetPassword = async (email) => {
  return axiosInstance
    .post(`${API_AUTH_URL}/request-password-reset`, { email })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response ? err.response.data : { error: err.message };
    });
};

const changePassword = (payload) => {
  return axiosInstance.post(`${API_AUTH_URL}/verify-password-reset`, {
    ...payload,
  });
};

const verifyToken = async (token) => {
  return axiosInstance
    .get(`${API_AUTH_URL}/verify-token?token=${token}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response ? err.response.data : { error: err.message };
    });
};

const logout = () => {
  localStorage.removeItem('accessToken');
  removeTokenCookie();
};

const getAllEmails = () => {
  return axiosInstance.get(API_USER_URL + '/emails', { headers: authHeader() });
};

const authService = {
  signup,
  login,
  logout,
  getAllEmails,
  resetPassword,
  changePassword,
  verifyOTP,
  resendOTP,
  validateEmail,
  verifyToken,
};

export default authService;
